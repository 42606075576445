import React, { useState, useEffect } from 'react';
import axios from 'axios';
import logo from './logo.svg';
import './App.css';

function App() {
  const [results, setResults] = useState([]);

  useEffect(() => {
    async function getResults() {
      try {
        const response = await axios.get(`https://resultapi.g8rage.com/api/v1/result`);

        if (response.status === 200) {
          setResults(response.data.result);
        } else {
          console.error('Request failed with status code:', response.status);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }

    getResults();
  }, []);

  return (
    <div className="App">
      <div className="event-name">Wrak Race Silesia</div>
      <div className="event-stage">Odcinek Chirdonhead II</div>
      <div className="result-container">
        <div key={0} className="header-container">
          <div className="header-index"></div>
          <div className="header-driver">Zespół</div>
          <div className="header-result">Wynik</div>
        </div>
        {results.map((entry, index) => (
          <div key={index} className="entry-container">
            <div className="entry-index">{index + 1}</div>
            <div className="entry-driver">{entry.driver}</div>
            <div className="entry-result">{entry.result}</div>
          </div>
        ))}
      </div>
      <div className="app-description">
        Serwowane przez <a href="mailto:hi@meshmeaning.com">MeshMeaning</a>
        <br></br>
        dla społeczności <a href="https://g8rage.com/pl">G8rage</a>
      </div>
    </div>
  );
}

export default App;
